import React, { useState } from 'react';

function PhoneAuth() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');

  const handleSendVerification = () => {
    // 인증 번호 전송 로직
    console.log('인증 번호 전송:', phoneNumber);
  };

  const handleVerify = () => {
    // 인증 번호 확인 로직
    console.log('인증 확인:', verificationCode);
  };

  return (
    <div className="phone-auth-container">
      <h2>핸드폰 번호 인증</h2>
      <input
        type="tel"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        placeholder="핸드폰 번호 입력"
      />
      <button onClick={handleSendVerification}>인증번호 전송</button>
      <input
        type="text"
        value={verificationCode}
        onChange={(e) => setVerificationCode(e.target.value)}
        placeholder="인증번호 입력"
      />
      <button onClick={handleVerify}>인증 확인</button>
    </div>
  );
}

export default PhoneAuth;
