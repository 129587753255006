import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PhoneAuth from './components/PhoneAuth';
import TicketPurchase from './components/TicketPurchase';
import TicketTransfer from './components/TicketTransfer';
import './App.css';  // CSS 파일 import


function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/" component={PhoneAuth} />
          <Route path="/purchase" component={TicketPurchase} />
          <Route path="/transfer" component={TicketTransfer} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
