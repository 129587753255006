import React, { useState } from 'react';

function TicketTransfer() {
  const [recipientPhone, setRecipientPhone] = useState('');
  const [ticketId, setTicketId] = useState('');

  const handleTransfer = () => {
    // 티켓 양도 로직
    console.log('티켓 양도:', ticketId, '수신자:', recipientPhone);
  };

  return (
    <div className="ticket-transfer-container">
      <h2>티켓 양도</h2>
      <input
        type="text"
        value={ticketId}
        onChange={(e) => setTicketId(e.target.value)}
        placeholder="티켓 ID 입력"
      />
      <input
        type="tel"
        value={recipientPhone}
        onChange={(e) => setRecipientPhone(e.target.value)}
        placeholder="수신자 핸드폰 번호"
      />
      <button onClick={handleTransfer}>양도하기</button>
    </div>
  );
}

export default TicketTransfer;
