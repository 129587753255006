import React, { useState } from 'react';

function TicketPurchase() {
  const [ticketType, setTicketType] = useState('');
  const [quantity, setQuantity] = useState(1);

  const handlePurchase = () => {
    // 티켓 구매 로직
    console.log('티켓 구매:', ticketType, quantity);
  };

  return (
    <div className="ticket-purchase-container">
      <h2>티켓 구매</h2>
      <select value={ticketType} onChange={(e) => setTicketType(e.target.value)}>
        <option value="">티켓 종류 선택</option>
        <option value="concert">콘서트</option>
        <option value="movie">영화</option>
        <option value="sports">스포츠</option>
      </select>
      <input
        type="number"
        value={quantity}
        onChange={(e) => setQuantity(parseInt(e.target.value))}
        min="1"
      />
      <button onClick={handlePurchase}>구매하기</button>
    </div>
  );
}

export default TicketPurchase;
